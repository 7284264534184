import api from '@/api/services'
import {notify} from "@/helpers/notifyHelper"
import Vue from 'vue'

export default {
	name: 'AdminShowArticle',
	data () {
		return {
			valid: false,
			postData: null,
			file: null,
			images: null,
			bodyImages: null,
			mobileImages: null,
			fileTitleImage: null,
			titleImage: null,
			mobileFileTitleImage: null
		}
	},
	created() {
		if (this.id) {
			api.getById(this, this.id)
				.then(response => {
					if (response.data.length) {
						this.postData = response.data[0];
					}
				})
				.catch(err => {
					notify(self, 'error', err)
				})
		}
	},
	methods: {
		removeFromPost (field) {
			Vue.delete(this.postData, field)
			api.deleteColumn(this, this.postData.id, field)
		},
		clearFile(name) {
			this.postData[name] = null
		},
		update () {
			if (this.$refs.form.validate()) {
				if (!this.postData.titleImage && !this.titleImage) {
					notify(this, 'error', 'You have to load title image')
					return
				}

				var regExpression = /^[a-zA-Z0-9-]*$/
				if (!regExpression.test(this.postData.urlName)) {
					notify(this, 'error', 'Link may be string and - ')
					return
				}

				if (
					this.images && this.file ||
					this.postData.images && this.file ||
					this.images && this.postData.file
				) {
					notify(this, 'error', 'You can load only file or images')
					return
				}

				if (this.images && this.images.length > 6) {
					this.images = null
					notify(this, 'error', 'You have to load no more 6 head images')
					return
				}

				if (this.bodyImages && this.bodyImages.length > 6) {
					this.bodyImages = null
					notify(this, 'error', 'You have to load no more 6 body images')
					return
				}

				if (this.mobileImages && this.mobileImages.length > 6) {
					this.mobileImages = null
					notify(this, 'error', 'You have to load no more 6 mobile images')
					return
				}

				let data = new FormData()
				Object.keys(this.postData).map(key => {
					data.append(key, this.postData[key])
				})

				if (this.images) {
					for (let i = 0; i < this.images.length; i++) {
						data.append('images', this.images[i]);
					}
				}

				if (this.bodyImages) {
					for (let i = 0; i < this.bodyImages.length; i++) {
						data.append('bodyImages', this.bodyImages[i]);
					}
				}

				if (this.file) {
					data.append('file', this.file);
				}

				if (this.titleImage) {
					data.append('titleImage', this.titleImage);
				}

				if (this.mobileImages) {
					for (let i = 0; i < this.mobileImages.length; i++) {
						data.append('mobileImages', this.mobileImages[i]);
					}
				}

				if (this.fileTitleImage) {
					data.append('fileTitleImage', this.fileTitleImage);
				}

				if (this.mobileFileTitleImage) {
					data.append('mobileFileTitleImage', this.mobileFileTitleImage);
				}

				api.update(this, this.postData.id, data)
					.then(() => {
						this.$router.push({name: 'AdminServices'})
					})
			}
		}
	},
	computed: {
		id () {
			return this.$route.params.id
		},
		postImages() {
			if (this.postData?.images) {
				if (typeof this.postData.images === 'string') {
					return JSON.parse(this.postData.images)
				}
				return this.postData.images
			}
			return []
		},
		mobilePostImages() {
			if (this.postData?.mobileImages) {
				if (typeof this.postData.mobileImages === 'string') {
					return JSON.parse(this.postData.mobileImages)
				}
				return this.postData.mobileImages
			}
			return []
		},
		postBodyImages() {
			if (this.postData?.bodyImages) {
				if (typeof this.postData.bodyImages === 'string') {
					return JSON.parse(this.postData.bodyImages)
				}
				return this.postData.bodyImages
			}
			return []
		}
	}
}
